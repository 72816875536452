import { EFormPaymentTypes } from '~/components/M/Forms/form.types';
import type { IFormCallMasterModel } from '~/components/M/Forms/MFormCallMaster/MFormCallMaster.model';

export interface IFormCashbackModel {
  name: string | number | Date | null;
  phone: string | number | Date | null;
  email: string | number | Date | null;
  links: string[];
  payment_type: EFormPaymentTypes;
  payment_requisites: string | number | Date | null;
  captcha?: string | null;
  captcha_key?: string | null;
}

/**
 * @description Cashback form model
 */
export class FormCashbackModel {
  public name: IFormCashbackModel['name'];
  public phone: IFormCashbackModel['phone'];
  public email: IFormCashbackModel['email'];
  public links: IFormCashbackModel['links'];
  public payment_type: IFormCashbackModel['payment_type'];
  public payment_requisites: IFormCashbackModel['payment_requisites'];
  public captcha: IFormCallMasterModel['captcha'];
  public captcha_key: IFormCallMasterModel['captcha_key'];

  constructor(attrs: Partial<IFormCashbackModel> | null = {}, enableCaptcha: boolean = false) {
    attrs = attrs ?? {};

    this.name = attrs.name || null;
    this.phone = attrs.phone || null;
    this.email = attrs.email || null;
    this.links = attrs.links || [];
    this.payment_type = EFormPaymentTypes.PHONE || null;
    this.payment_requisites = attrs.payment_requisites || null;

    if (enableCaptcha) {
      this.captcha = attrs.captcha || null;
      this.captcha_key = attrs.captcha_key || null;
    }
  }

  public get captchaEnabled() {
    return this.captcha;
  }

  public set captchaEnabled(captcha: IFormCallMasterModel['captcha']) {
    this.captcha = captcha;
  }

  public reset() {
    this.name = null;
    this.phone = null;
    this.email = null;
    this.links = [];
    this.payment_type = EFormPaymentTypes.PHONE;
    this.payment_requisites = null;
    this.captcha = this.captchaEnabled ? null : undefined;
    this.captcha_key = this.captchaEnabled ? null : undefined;
  }

  public toJSON(): IFormCashbackModel {
    return {
      name: this.name,
      phone: this.phone,
      email: this.email,
      links: this.links,
      payment_type: this.payment_type,
      payment_requisites: this.payment_requisites,
      captcha: this.captchaEnabled ? this.captcha : undefined,
      captcha_key: this.captchaEnabled ? this.captcha_key : undefined,
    };
  }
}
